<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  type="password"
                  label="Contraseña Actual:"
                  :lazy="false"
                  :value.sync="$v.form.old_password.$model"
                  invalidFeedback="La contraseña actual es obligatoria."
                  :isValid="checkIfValid('old_password')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  type="password"
                  label="Nueva Contraseña:"
                  :lazy="false"
                  :value.sync="$v.form.new_password.$model"
                  invalidFeedback="Debe tener al menos 8 caracteres y teniendo al menos un número, una mayúscula y una minúscula."
                  :isValid="checkIfValid('new_password')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  type="password"
                  label="Confirmar Contraseña:"
                  :lazy="false"
                  :value.sync="$v.form.confirmPassword.$model"
                  invalidFeedback="Las contraseñas no coinciden."
                  :isValid="checkIfValid('confirmPassword')"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../../../services/suppliers';
import managers_ws from '../../../../services/managers';
import account_ws from '../../../../services/account';
import store from '../../../../store'

export default {
  name: 'SupplierPasswordModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Vendedor",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      link: '',
      managers: [],
      managersOptions: [],
      cedisOptions:[],
      user: null
    }
  },
  mounted: async function() {
    this.form = {
        id: 0,
        code: '',
        name: ''
    }
  },
  methods: {
    updateModal (user) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Información de Acceso';
      this.isEdit = true;
      this.submitted = false;

      this.user = user;

      this.form = {
        id: this.user.id,
        email: this.user.email,
        old_password: "",
        new_password: "",
        confirmPassword: ""
      }
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("update", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    redirectLink(link){
      window.location = link;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {},
      new_password: {
        required,
        minLength: minLength(8),
        strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      },
      old_password: {
        required
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("new_password")
      }
    }
  },
}
</script>