<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Dirección:"
                  :lazy="false"
                  :value.sync="$v.form.address.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Colonia:"
                  :lazy="false"
                  :value.sync="$v.form.colony.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Ciudad:"
                  :lazy="false"
                  :value.sync="$v.form.city.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Estado:"
                  :lazy="false"
                  :value.sync="$v.form.state.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Código Postal:"
                  :lazy="false"
                  :value.sync="$v.form.zipcode.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Whatsapp:"
                  :lazy="false"
                  :value.sync="$v.form.whatsapp.$model"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../../../services/suppliers';
import managers_ws from '../../../../services/managers';
import account_ws from '../../../../services/account';
import store from '../../../../store'

export default {
  name: 'SupplierContactModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Vendedor",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      link: '',
      managers: [],
      managersOptions: [],
      cedisOptions:[],
      user: null
    }
  },
  mounted: async function() {
    this.form = {
        id: 0,
        code: '',
        name: ''
    }
  },
  methods: {
    updateModal (user) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Información de Contacto';
      this.isEdit = true;
      this.submitted = false;

      this.user = user;

      this.form = {
        id: this.user.id,
        address: this.user.address,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        colony: this.user.colony,
        city: this.user.city,
        state: this.user.state,
        zipcode: this.user.zipcode,
        whatsapp: this.user.whatsapp,
      }
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("update", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    redirectLink(link){
      window.location = link;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      address: {
        
      },
      colony: {
        
      },
      city: {
        
      },
      state: {
        
      },
      zipcode: {
        
      },
      whatsapp: {
        
      },
    }
  },
}
</script>